import Phaser, { UP } from 'phaser'
import { Event } from '../events/Event'
import { sceneEvents } from '../events/EventCenter'
import CustomKeymap, { KEY_ACTIONS } from '../utils/CustomKeymap'

declare global {
  namespace Phaser.GameObjects {
    interface GameObjectFactory {
      hacker(x: number, y: number, texture: string, frame?: string | number): Hacker
    }
  }
}

const SPEED = 140

export default class Hacker extends Phaser.Physics.Arcade.Sprite {
  private _currentInteract: any

  constructor(scene: Phaser.Scene, x: number, y: number, texture: string, frame?: string | number) {
    super(scene, x, y, texture, frame)

    this.anims.play('hacker-idle-down')
  }

  update(cursors: CustomKeymap): void {
    if (!cursors) return

    // Stop movement by default
    this.setVelocity(0)
    const UP_DOWN = cursors.isDown(KEY_ACTIONS.UP)
    const LEFT_DOWN = cursors.isDown(KEY_ACTIONS.LEFT)
    const DOWN_DOWN = cursors.isDown(KEY_ACTIONS.DOWN)
    const RIGHT_DOWN = cursors.isDown(KEY_ACTIONS.RIGHT)
    const INTERACT_JDOWN = cursors.justDown(KEY_ACTIONS.INTERACT)

    // Interact
    if (INTERACT_JDOWN) {
      if (this._currentInteract) {
        sceneEvents.emit(Event.PLAYER_INTERACT, this._currentInteract)
      }

      return
    }

    // Vertical movement
    if (UP_DOWN) this.setVelocityY(-SPEED)
    else if (DOWN_DOWN) this.setVelocityY(SPEED)

    // Horizontal movement
    if (LEFT_DOWN) this.setVelocityX(-SPEED)
    else if (RIGHT_DOWN) this.setVelocityX(SPEED)

    // Norm & Scale speed *Fix move faster along diagonal
    this.body.velocity.normalize().scale(SPEED)

    // Update movement animation
    if (LEFT_DOWN) this.anims.play('hacker-walk-left', true)
    else if (RIGHT_DOWN) this.anims.play('hacker-walk-right', true)
    else if (UP_DOWN) this.anims.play('hacker-walk-up', true)
    else if (DOWN_DOWN) this.anims.play('hacker-walk-down', true)
    else {
      // Idle last movement frame
      const idleAnims = this.anims.currentAnim?.key.replace('walk', 'idle')
      if (idleAnims) this.anims.play(idleAnims, true)
    }

    if (LEFT_DOWN || RIGHT_DOWN || UP_DOWN || DOWN_DOWN) {
      this._currentInteract = undefined
    }
  }

  setInteract(interact: any) {
    this._currentInteract = interact
  }
}

Phaser.GameObjects.GameObjectFactory.register(
  'hacker',
  function (
    this: Phaser.GameObjects.GameObjectFactory,
    x: number,
    y: number,
    texture: string,
    frame?: string | number
  ) {
    var sprite = new Hacker(this.scene, x, y, texture, frame)

    this.displayList.add(sprite)
    this.updateList.add(sprite)

    this.scene.physics.world.enableBody(sprite, Phaser.Physics.Arcade.DYNAMIC_BODY)
    sprite.body.setSize(32, sprite.height * 0.7)
    sprite.body.setOffset(7, 25)

    return sprite
  }
)
