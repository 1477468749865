import Phaser from 'phaser'
import TownScene from './scene/TownScene'
import Preload from './scene/Preload'
import RexUIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin'
import RoundRectanglePlugin from 'phaser3-rex-plugins/plugins/roundrectangle-plugin.js'
import HardwareUI from './scene/HardwareUI'
import HideoutScene from './scene/HideoutScene'

const ZOOM_LEVEL = 1 + window.innerHeight / window.innerWidth

// ------------------------------------------------------------------------------------
// PLUGIN
const rexUI: Phaser.Types.Core.PluginObjectItem = {
  key: 'rexUI',
  plugin: RexUIPlugin,
  mapping: 'rexUI',
}

const rexRoundRectangle: Phaser.Types.Core.PluginObjectItem = {
  key: 'rexRoundRectanglePlugin',
  plugin: RoundRectanglePlugin,
  start: true,
}

// ------------------------------------------------------------------------------------
// GAME OBJECT
const game = new Phaser.Game({
  type: Phaser.AUTO,
  pixelArt: true,
  autoFocus: true,
  scale: {
    mode: Phaser.Scale.ScaleModes.NONE,
    autoCenter: Phaser.Scale.NO_CENTER,
    width: window.innerWidth / ZOOM_LEVEL,
    height: window.innerHeight / ZOOM_LEVEL,
    zoom: ZOOM_LEVEL,
  },
  physics: {
    default: 'arcade',
    arcade: {
      gravity: { y: 0 },
      debug: false,
    },
  },
  scene: [Preload, TownScene, HideoutScene, HardwareUI],
  plugins: {
    global: [rexRoundRectangle],
    scene: [rexUI],
  },
})

export default game

// ------------------------------------------------------------------------------------
// SCREEN RESIZE LISTENER

window.addEventListener(
  'resize',
  () => {
    game.scale.setZoom(1 + window.innerHeight / window.innerWidth)
    game.scale.resize(window.innerWidth / game.scale.zoom, window.innerHeight / game.scale.zoom)
  },
  false
)
