import Phaser from 'phaser'
import { ethers } from 'ethers'
import { walletSwitchChain } from '../utils/WalletUtils'
require('dotenv').config()

export default class Preload extends Phaser.Scene {
  constructor() {
    super({
      key: 'Preload',
      pack: {
        files: [
          {
            type: 'image',
            key: 'hacker-town-logo',
            url: 'image/logo_full.png',
          },
        ],
      },
    })
  }

  async preload() {
    this.load.image('cyber-ex', 'tiles/CyberEx.png')
    this.load.tilemapTiledJSON('cyber-town', 'tiles/HackerTown.json')

    this.load.atlas('hacker', 'characters/hacker_x48.png', 'characters/hacker_x48.json')
    this.load.atlas('door-ex', 'objects/DoorEx.png', 'objects/DoorEx.json')

    this.load.image('hideout-img', 'tiles/Hideout.png')
    this.load.tilemapTiledJSON('Hideout', 'tiles/Hideout.json')

    this.load.bitmapFont('press-start', 'fonts/pressstart2p.png', 'fonts/pressstart2p.fnt')

    // Hardware asset
    this.load.image('cpu-0', 'image/cpu_common.png')
    this.load.image('cpu-1', 'image/cpu_rare.png')
    this.load.image('cpu-2', 'image/cpu_legend.png')

    this.load.image('mainboard-0', 'image/mainboard_common.png')
    this.load.image('mainboard-1', 'image/mainboard_rare.png')
    this.load.image('mainboard-2', 'image/mainboard_legend.png')

    this.load.image('ram-0', 'image/ram_common.png')
    this.load.image('ram-1', 'image/ram_rare.png')
    this.load.image('ram-2', 'image/ram_legend.png')

    this.load.image('harddisk-0', 'image/harddisk_common.png')
    this.load.image('harddisk-1', 'image/harddisk_rare.png')
    this.load.image('harddisk-2', 'image/harddisk_legend.png')

    this.showProgress(window.innerWidth / this.scale.zoom, window.innerHeight / this.scale.zoom, this)
  }

  create() {
    setTimeout(() => {
      if (process.env.ENVIRONMENT === 'dev') {
        this.cameras.main.once(Phaser.Cameras.Scene2D.Events.FADE_OUT_COMPLETE, () => {
          this.scene.start('TownScene')
        })
      } else {
        const { ethereum } = window as any
        if (ethereum) {
          walletSwitchChain(ethereum).then(async () => {
            const accounts = await ethereum.request({
              method: 'eth_requestAccounts',
            })
            if (accounts[0]) {
              const provider = new ethers.providers.Web3Provider(ethereum)
              const signer = provider.getSigner()
              const signature = await signer.signMessage('signin')
              const address = await signer.getAddress()
              if (signature && address) localStorage.setItem('address', address)
              this.scene.start('TownScene')
            }
          })
        } else {
          // TODO: Show message Need Metamask to process
        }
      }
    }, 1500)
  }

  showProgress = (sceneW: number, sceneH: number, scene: Phaser.Scene) => {
    const progressBar = this.add.graphics()
    const progressBox = this.add.graphics()

    progressBox.fillStyle(0x222222, 0.8)
    progressBox.fillRect(sceneW / 2 - 160, sceneH / 2 - 25, 320, 50)

    const loadingText = this.make
      .text({
        x: sceneW / 2,
        y: sceneH / 2 - 50,
        text: 'Loading...',
        style: {
          font: '20px monospace',
        },
      })
      .setOrigin(0.5, 0.5)

    const percentText = this.make
      .text({
        x: sceneW / 2,
        y: sceneH / 2,
        text: '0%',
        style: {
          font: '18px monospace',
        },
      })
      .setOrigin(0.5, 0.5)

    scene.load.on('progress', (value) => {
      percentText.setText(`${Math.round(value * 100)} %`)
      progressBar.clear()
      progressBar.fillStyle(0xffffff, 1)
      progressBar.fillRect(sceneW / 2 - 150, sceneH / 2 - 15, 300 * value, 30)
    })

    scene.load.once('complete', () => {
      progressBar.destroy()
      progressBox.destroy()
      loadingText.destroy()
      percentText.destroy()
      const img = this.add.image(sceneW / 2, sceneH / 2, 'hacker-town-logo')
      img.setScale(window.innerHeight / window.innerWidth)
      this.cameras.main.fadeOut(1700, 0, 0, 0)
    })
  }
}
