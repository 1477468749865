import Phaser from 'phaser'
import FixWidthSizer from 'phaser3-rex-plugins/templates/ui/fixwidthsizer/FixWidthSizer'
import { ScrollablePanel } from 'phaser3-rex-plugins/templates/ui/ui-components.js'
import RexUIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin.js'
import { Event } from '../events/Event'
import { sceneEvents } from '../events/EventCenter'
import { getInventory } from '../utils/WalletUtils'

const COLOR_PRIMARY = 0x000000
const COLOR_LIGHT = 0xffffff
const COLOR_DARK = 0x000000

export default class HardwareUI extends Phaser.Scene {
  rexUI: RexUIPlugin
  inventory: any

  constructor() {
    super('HardwareUI')
  }

  preload() {
    this.load.image('ic-close', 'image/ic_close_white.png')
  }

  async create() {
    const { ethereum } = window as any
    this.inventory = await getInventory(ethereum)

    const closeButton = this.rexUI.add.buttons({
      name: 'close-btn',
      click: {
        mode: 'pointerdown',
      },
      buttons: [this.add.image(0, 0, 'ic-close').setScale(0.5).setDepth(2)],
    })

    closeButton.on('button.click', () => {
      sceneEvents.emit(Event.CLOSE_UI_SCENE, 'HardwareUI')
    })

    const sizer = this.rexUI.add
      .overlapSizer({
        anchor: {
          centerX: 'center',
          centerY: 'center',
          // @ts-expect-error
          width: '100%-100',
          height: '100%-80',
          onResizeCallback: function (width, height, gameObject, anchor) {
            gameObject.setAnchor({
              centerX: 'center',
              centerY: 'center',
              width: '100%-100',
              height: '100%-80',
            })
          },
        },
      })
      .add(this.rexUI.add.roundRectangle(0, 0, 0, 0, 0, COLOR_DARK, 0.8), 'main')
      .add(this.rexUI.add.roundRectangle(0, 0, 0, 40, 0), {
        key: 'header',
        align: 'center-top',
        padding: { left: 80, right: 80, top: 10, bottom: 10 },
        expand: {
          width: true,
        },
      })
      .add(
        this.rexUI.add.label({
          text: this.add.bitmapText(0, 0, 'press-start', 'My Hardware', 16).setTintFill(0xeaa742),
          align: 'center',
          space: {
            left: 10,
            right: 10,
            top: 12,
            bottom: 12,
          },
        }),
        {
          key: 'text',
          align: 'center-top',
          padding: { left: 80, right: 80, top: 10, bottom: 10 },
          expand: false,
        }
      )
      .add(closeButton, {
        key: 'close',
        align: 'right-top',
        padding: { right: 20, top: 20 },
        expand: false,
      })
      .add(
        this.rexUI.add.scrollablePanel({
          scrollMode: 0,
          panel: {
            child: this.createPanel(this),
            mask: {
              padding: 1,
            },
          },
          slider: {
            track: this.rexUI.add.roundRectangle(0, 0, 9, 0, 0, COLOR_DARK, 0.9),
            thumb: this.rexUI.add.roundRectangle(0, 0, 9, 120, 4, COLOR_LIGHT, 0.8),
          },
          mouseWheelScroller: {
            focus: false,
            speed: 0.3,
          },
          space: {
            left: 80,
            panel: 10,
          },
        }),
        {
          key: 'scroll-panel',
          align: 'center-bottom',
          padding: { left: 20, right: 20, top: 70, bottom: 20 },
          expand: true,
        }
      )
      .layout()
    // .drawBounds(this.add.graphics(), 0xff0000)
  }

  createPanel(scene, data?) {
    const sizer = scene.rexUI.add.fixWidthSizer({
      space: {
        left: 24,
        right: 24,
        top: 24,
        bottom: 24,
        item: 20,
        line: 24,
      },
    })

    this.inventory?.map((item: any) => {
      const {
        type,
        data: { rarity },
      } = item

      sizer.add(
        scene.rexUI.add.label({
          width: 120,
          height: 120,
          background: scene.add.image(0, 0, `${type}-${rarity}`),
          align: 'center',
          space: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 10,
          },
        }),
        {
          key: 'item',
          expand: false,
        }
      )
    })

    return sizer
  }
}
