const HAlign = {
    left: 0,
    center: 1,
    right: 2
}

const VAlign = {
    top: 0,
    center: 1,
    bottom: 2
}

export {
    HAlign,
    VAlign
}