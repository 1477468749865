import { ethers } from 'ethers'
import HackerTownCPU from '../json/HackerTownCPU.json'
import HackerTownMainboard from '../json/HackerTownMainboard.json'
import HackerTownRAM from '../json/HackerTownRAM.json'
import HackerTownStorage from '../json/HackerTownStorage.json'

//test net
const hackerTownGameAddress: string = '0x1ed79df44CC1Add98ECeD4e1cBD5A557700F2D7d'
const hackerTownCPUAddress: string = '0x93bB03bFe78213b092C1a163796f0cB184A2d1e3'
const hackerTownMainboardAddress: string = '0xbE107C6ed9004FF65C6865AdAc228439FEfff614'
const hackerTownRamAddress: string = '0xAEa6D40278e98f7630F91CD170941b56c949660C'
const hackerTownStorageAddress: string = '0x73a57a65276fc6382c7A2d30Cb2d92A88581e445'

export async function walletSwitchChain(ethereum: any) {
  const res = await ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [
      {
        chainId: '0x61',
      },
    ],
  })
  return res
}

export async function getInventory(ethereum: any) {
  if (ethereum) {
    return walletSwitchChain(ethereum).then(async () => {
      const inventory: any = []
      const addressWallet = localStorage.getItem('address')
      const provider = new ethers.providers.Web3Provider(ethereum)
      const signer = provider.getSigner()

      // connect contract
      const hackerTownCPU = new ethers.Contract(hackerTownCPUAddress, HackerTownCPU, signer)
      const hackerTownMainboard = new ethers.Contract(hackerTownMainboardAddress, HackerTownMainboard, signer)
      const hackerTownRAM = new ethers.Contract(hackerTownRamAddress, HackerTownRAM, signer)
      const hackerTownStorage = new ethers.Contract(hackerTownStorageAddress, HackerTownStorage, signer)

      // get my nft
      let cpu: any = await hackerTownCPU.getNFTByOwner(addressWallet)
      let mainboard: any = await hackerTownMainboard.getNFTByOwner(addressWallet)
      let ram: any = await hackerTownRAM.getNFTByOwner(addressWallet)
      let storage: any = await hackerTownStorage.getNFTByOwner(addressWallet)

      const newItems = { cpu, mainboard, ram, storage }
      const mapNewItems: any = []
      if (newItems.cpu)
        for (const i of newItems.cpu) {
          mapNewItems.push({ type: 'cpu', contract: hackerTownCPU, _hex: i._hex })
        }
      if (newItems.mainboard)
        for (const i of newItems.mainboard) {
          mapNewItems.push({ type: 'mainboard', contract: hackerTownMainboard, _hex: i._hex })
        }
      if (newItems.ram)
        for (const i of newItems.ram) {
          mapNewItems.push({ type: 'ram', contract: hackerTownRAM, _hex: i._hex })
        }
      if (newItems.storage)
        for (const i of newItems.storage) {
          mapNewItems.push({ type: 'harddisk', contract: hackerTownStorage, _hex: i._hex })
        }

      // get detail by nft
      for (const i of mapNewItems) {
        const res = await i.contract.devices(i._hex)
        const uri = await i.contract.tokenURI(i._hex)
        inventory.push({ type: i.type, data: res, uri })
      }

      if (process.env.ENVIRONMENT === 'dev') {
        inventory.push({ type: 'harddisk', data: { rarity: 0 } })
        inventory.push({ type: 'harddisk', data: { rarity: 1 } })
        inventory.push({ type: 'harddisk', data: { rarity: 2 } })

        inventory.push({ type: 'cpu', data: { rarity: 0 } })
        inventory.push({ type: 'cpu', data: { rarity: 1 } })
        inventory.push({ type: 'cpu', data: { rarity: 2 } })

        inventory.push({ type: 'ram', data: { rarity: 0 } })
        inventory.push({ type: 'ram', data: { rarity: 1 } })
        inventory.push({ type: 'ram', data: { rarity: 2 } })

        inventory.push({ type: 'mainboard', data: { rarity: 0 } })
        inventory.push({ type: 'mainboard', data: { rarity: 1 } })
        inventory.push({ type: 'mainboard', data: { rarity: 2 } })

        console.log(inventory)
      }

      return inventory
    })
  } else {
    // TODO: Show message Need Metamask to process
    console.log('Need Metamask to process')
  }
}
