import Phaser from 'phaser'

export default class Door extends Phaser.Physics.Arcade.Sprite {
  public subBody: Phaser.Types.Physics.Arcade.ImageWithDynamicBody

  constructor(scene: Phaser.Scene, x: number, y: number, texture: string, frame?: string | number) {
    super(scene, x, y, texture, frame)

    this.play('light-door-idle', true)

    // ----------------------------------------------------------------------------------------
    // WIP PHYSICS BODY IN SAME SPRITE

    // @ts-expect-error
    this.subBody = scene.physics.add.image()
    this.subBody.setSize(this.width, this.height)
    this.subBody.setDebugBodyColor(0xffff00)
    this.subBody.setPosition(this.x, this.y)
  }

  open(scene: Phaser.Scene) {
    if (this.anims.currentAnim.key !== 'light-door-idle') return

    this.on('animationcomplete', () => {
      this.setBodySize(this.width, this.height / 2, false)
      scene.physics.world.colliders
        .getActive()
        .find((actCollide) => actCollide.name === 'DoorCollide')
        ?.destroy()
    })

    this.play('light-door-open', true)
  }

  close() {
    if (this.anims.currentAnim.key !== 'light-door-open') return

    this.playReverse('light-door-open', true)
  }
}
