import Phaser from 'phaser'
import { createHackerAnimations } from '../anims/HackerAnims'
import Hacker from '../characters/Hacker'
import { Event } from '../events/Event'
import { sceneEvents } from '../events/EventCenter'
import Door from '../objects/Door'
import CustomKeymap from '../utils/CustomKeymap'

export default class HideoutScene extends Phaser.Scene {
  private hacker!: Hacker
  private key!: any
  private map: Phaser.Tilemaps.Tilemap
  private spawn!: {
    x: any
    y: any
  }
  constructor() {
    super('HideoutScene')
  }

  preload() {
    // this.load.scenePlugin(
    //   'PhaserDebugDrawPlugin',
    //   'https://cdn.jsdelivr.net/npm/phaser-plugin-debug-draw@7.0.0',
    //   'debugDraw',
    //   'debugDraw'
    // )
    this.key = new CustomKeymap(this.input.keyboard)
  }

  create() {
    this.cameras.main.fadeIn(700, 0, 0, 0)

    createHackerAnimations(this.anims)

    this.map = this.make.tilemap({
      key: 'Hideout',
      tileWidth: 24,
      tileHeight: 24,
    })

    const map = this.map
    const tileset = map.addTilesetImage('hideout', 'hideout-img')

    const doors = this.physics.add.staticGroup()

    const computerGroup = this.physics.add.staticGroup()

    const ObjectLayer = map.getObjectLayer('ObjectLayer')

    ObjectLayer.objects.forEach((object) => {
      if (object.type === 'door') {
        const data = {}
        object.properties.forEach((prop) => (data[prop.name] = prop.value))

        doors
          .get(object.x! + object.width! / 2, object.y! + object.height!)
          .setBodySize(object.width, object.height)
          .setData(data)
      } else if (object.type === 'spawn') {
        this.spawn = { x: object.x, y: object.y }
      } else if (object.name === 'Computer') {
        computerGroup
          .get(object.x! + object.width! / 2, object.y! + object.height! - 10)
          .setOrigin(0.5)
          .setSize(object.width, object.height)
          .setName(object.name)
      }
    })

    // Create all layer
    map.layers.map((layer) => {
      if (!new RegExp('Parallax|Collision').test(layer.name))
        return map.createLayer(layer.name, tileset).setDisplayOrigin(0, 0)
    })

    this.hacker = this.add.hacker(this.spawn.x, this.spawn.y, 'hacker')
    this.hacker.anims.play('hacker-idle-up')

    map.layers.map((layer) => {
      if (new RegExp('Parallax').test(layer.name)) return map.createLayer(layer.name, tileset).setDisplayOrigin(0, 0)
    })

    this.cameras.main.setBounds(0, 0, map.widthInPixels, map.heightInPixels)
    this.cameras.main.setPosition(
      (this.scale.width - map.widthInPixels) / 2,
      (this.scale.height - map.heightInPixels) / 2
    )
    this.cameras.main.startFollow(this.hacker, true)

    this.scale.on('resize', this.resize, this)

    const CollisionTile = map
      .createLayer('Collision', tileset)
      .setAlpha(0)
      .setCollisionByProperty({ collision: true })
      .setDisplayOrigin(0, 0)

    this.physics.add.collider(this.hacker, CollisionTile)
    this.physics.add.collider(this.hacker, computerGroup, this.handleHackerComputerCollision, undefined, this)

    this.physics.add.overlap(this.hacker, doors, this.enterDoor, undefined, this)

    // ----------------------------------------------------------------------------------------
    // EVENTS

    sceneEvents.on(Event.PLAYER_INTERACT, (object: any) => {
      if (object.name === 'Computer') {
        this.scene.pause()
        this.scene.run('HardwareUI')
      }
    })

    sceneEvents.on(Event.CLOSE_UI_SCENE, (scene: string) => {
      this.scene.stop(scene)
      this.scene.resume()
    })

    this.events.once(Phaser.Scenes.Events.SHUTDOWN, () => {
      sceneEvents.off(Event.PLAYER_INTERACT)
      sceneEvents.off(Event.CLOSE_UI_SCENE)
    })
  }

  update() {
    if (this.hacker) this.hacker.update(this.key)
  }

  enterDoor(obj1: Phaser.GameObjects.GameObject, obj2: Phaser.GameObjects.GameObject) {
    this.scene.start(obj2.getData('link'))
  }

  handleHackerComputerCollision(obj1: Phaser.GameObjects.GameObject, obj2: Phaser.GameObjects.GameObject) {
    const computer = obj2
    this.hacker.setInteract(computer)
  }

  resize() {
    this.cameras.main.setPosition(
      (this.scale.width - this.map.widthInPixels) / 2,
      (this.scale.height - this.map.heightInPixels) / 2
    )
  }
}
