// Define your actions
export const KEY_ACTIONS = { LEFT: 0, UP: 1, RIGHT: 2, DOWN: 3, INTERACT: 4 }
// Define your keymap, as many keys per action as we want
const DEFAULT_MAP = {
  [KEY_ACTIONS.LEFT]: [Phaser.Input.Keyboard.KeyCodes.A, Phaser.Input.Keyboard.KeyCodes.LEFT],
  [KEY_ACTIONS.UP]: [Phaser.Input.Keyboard.KeyCodes.W, Phaser.Input.Keyboard.KeyCodes.UP],
  [KEY_ACTIONS.RIGHT]: [Phaser.Input.Keyboard.KeyCodes.D, Phaser.Input.Keyboard.KeyCodes.RIGHT],
  [KEY_ACTIONS.DOWN]: [Phaser.Input.Keyboard.KeyCodes.S, Phaser.Input.Keyboard.KeyCodes.DOWN],
  [KEY_ACTIONS.INTERACT]: [Phaser.Input.Keyboard.KeyCodes.E],
}

export default class CustomKeymap {
  private map: {}

  constructor(keyboard, defaultMap: any = DEFAULT_MAP) {
    this.map = {}
    Object.entries(defaultMap).forEach(([, keycode], action) => {
      this.map[action] = []
      if (Array.isArray(keycode)) {
        keycode.forEach((code) => {
          this.map[action].push(keyboard.addKey(code))
        })
      } else {
        this.map[action].push(keyboard.addKey(keycode))
      }
    })
  }

  isDown(action) {
    for (let i = 0, length = this.map[action].length; i < length; i++) {
      if (this.map[action][i].isDown) return true
    }
    return false
  }

  justDown(action) {
    for (let i = 0, length = this.map[action].length; i < length; i++) {
      if (Phaser.Input.Keyboard.JustDown(this.map[action][i])) return true
    }
    return false
  }
}
