import Phaser from 'phaser'

const createDoorAnimations = (anims: Phaser.Animations.AnimationManager) => {
  // Common light door
  anims.create({
    key: 'light-door-idle',
    frames: [{ key: 'door-ex', frame: 'door-light-f0.png' }],
    frameRate: 5,
  })

  anims.create({
    key: 'light-door-open',
    frames: anims.generateFrameNames('door-ex', {
      start: 0,
      end: 3,
      prefix: 'door-light-f',
      suffix: '.png',
    }),
    frameRate: 12,
  })
}

export { createDoorAnimations }
