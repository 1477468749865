import Phaser from 'phaser'
import { createDoorAnimations } from '../anims/DoorAnims'
import { createHackerAnimations } from '../anims/HackerAnims'
import '../characters/Hacker'
import Hacker from '../characters/Hacker'
import Door from '../objects/Door'
import AnimatedTiles from '../scripts/AnimatedTiles'
import CustomKeymap from '../utils/CustomKeymap'

const TILE_SIZE = 24

export default class TownScene extends Phaser.Scene {
  private hacker!: Hacker
  private key!: any
  private spawn!: {
    x: any
    y: any
  }

  constructor() {
    super('TownScene')
  }

  preload() {
    this.load.scenePlugin('AnimatedTiles', AnimatedTiles, 'animatedTiles', 'animatedTiles')
    this.key = new CustomKeymap(this.input.keyboard)
  }

  create() {
    this.cameras.main.fadeIn(600, 0, 0, 0)
    createHackerAnimations(this.anims)
    createDoorAnimations(this.anims)

    // ----------------------------------------------------------------------------------------
    // SETUP MAP & PLAYER

    const map = this.make.tilemap({ key: 'cyber-town' })
    const tileset = map.addTilesetImage('cyber_ex', 'cyber-ex', 24, 24)

    // Create all layer
    map.layers.map((layer) => {
      if (!new RegExp('Parallax|Collision|Atmosphere|Building shadow').test(layer.name))
        return map.createLayer(layer.name, tileset).setDepth(0).setDisplayOrigin(0, 0)
    })

    map.layers.map((layer) => {
      if (new RegExp('Parallax|Atmosphere|Building shadow').test(layer.name))
        return map.createLayer(layer.name, tileset).setDepth(2).setDisplayOrigin(0, 0)
    })

    const doors = this.physics.add.staticGroup({
      classType: Door,
    })

    const DoorsLayer = map.getObjectLayer('ObjectLayer')

    DoorsLayer.objects.forEach((object) => {
      if (object.type === 'door') {
        const data = {}
        object.properties?.forEach((prop) => (data[prop.name] = prop.value))

        doors
          .get(object.x! + object.width! / 2, object.y! - object.height! / 2, 'door-ex')
          .setBodySize(object.width, object.height!)
          .setData(data)
      }

      if (object.type === 'spawn') {
        this.spawn = { x: object.x, y: object.y }
      }
    })

    this.hacker = this.add.hacker(this.spawn.x, this.spawn.y, 'hacker').setDepth(1)

    // PLAY MAP ANIMATION
    // @ts-expect-error
    this.animatedTiles.init(map)

    const CollisionTile = map.createLayer('Collision', tileset).setDisplayOrigin(0, 0)
    CollisionTile.setAlpha(0).setCollisionByProperty({ collision: true })

    // ----------------------------------------------------------------------------------------
    // CAMERA

    this.cameras.main.setBounds(48, 48, (map.width - 4) * TILE_SIZE, (map.height - 4) * TILE_SIZE)
    this.cameras.main.startFollow(this.hacker, true)

    // ----------------------------------------------------------------------------------------
    // PHYSICS

    this.physics.add.collider(this.hacker, CollisionTile)
    this.physics.add.collider(this.hacker, doors, this.openDoor, undefined, this).setName('DoorCollide')

    this.physics.add.overlap(this.hacker, doors, this.enterDoor, undefined, this)
  }

  update() {
    if (this.hacker) this.hacker.update(this.key)
  }

  openDoor(obj1: Phaser.GameObjects.GameObject, obj2: Phaser.GameObjects.GameObject) {
    const door = obj2 as Door
    door.open(this)
  }

  enterDoor(obj1: Phaser.GameObjects.GameObject, obj2: Phaser.GameObjects.GameObject) {
    this.scene.start(obj2.getData('link'))
  }
}
