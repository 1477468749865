import Phaser from 'phaser'

const createHackerAnimations = (anims: Phaser.Animations.AnimationManager) => {
  // Idle anims
  anims.create({
    key: 'hacker-idle-down',
    frames: anims.generateFrameNames('hacker', {
      start: 1,
      end: 4,
      prefix: 'idle_down_f',
      suffix: '.png',
    }),
    repeat: -1,
    frameRate: 4,
  })

  anims.create({
    key: 'hacker-idle-left',
    frames: anims.generateFrameNames('hacker', {
      start: 1,
      end: 4,
      prefix: 'idle_left_f',
      suffix: '.png',
    }),
    repeat: -1,
    frameRate: 4,
  })

  anims.create({
    key: 'hacker-idle-right',
    frames: anims.generateFrameNames('hacker', {
      start: 1,
      end: 4,
      prefix: 'idle_right_f',
      suffix: '.png',
    }),
    repeat: -1,
    frameRate: 4,
  })

  anims.create({
    key: 'hacker-idle-up',
    frames: anims.generateFrameNames('hacker', {
      start: 1,
      end: 4,
      prefix: 'idle_up_f',
      suffix: '.png',
    }),
    repeat: -1,
    frameRate: 4,
  })

  // Move anims
  anims.create({
    key: 'hacker-walk-down',
    frames: anims.generateFrameNames('hacker', {
      start: 1,
      end: 4,
      prefix: 'walk_down_f',
      suffix: '.png',
    }),
    repeat: -1,
    frameRate: 8,
  })

  anims.create({
    key: 'hacker-walk-left',
    frames: anims.generateFrameNames('hacker', {
      start: 1,
      end: 4,
      prefix: 'walk_left_f',
      suffix: '.png',
    }),
    repeat: -1,
    frameRate: 8,
  })

  anims.create({
    key: 'hacker-walk-right',
    frames: anims.generateFrameNames('hacker', {
      start: 1,
      end: 4,
      prefix: 'walk_right_f',
      suffix: '.png',
    }),
    repeat: -1,
    frameRate: 8,
  })

  anims.create({
    key: 'hacker-walk-up',
    frames: anims.generateFrameNames('hacker', {
      start: 1,
      end: 4,
      prefix: 'walk_up_f',
      suffix: '.png',
    }),
    repeat: -1,
    frameRate: 8,
  })
}

export { createHackerAnimations }
